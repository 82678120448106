<template>
  <div class="signup-form">
    <form @submit.prevent="signupUser">
      <div class="form-group">
        <div>
          <p class="setup-text"> {{ $t("Set Up Password") }} </p>
          <p class="enhanced-text"> {{ $t("For enhanced account protection and convenient email login") }}</p>
        </div>
        <JhakaasInput 
         inputType="password" 
         :label="$t('password')" 
         inputId="userPassword" reference="password"
         :helperText="passwordError" 
         :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'" 
          autocomplete="false"
          @keydown.space.prevent required 
          :value="password" 
          @onChange="value => 
          password = value">
          <template v-slot:endIcon>
            <span class="eye">
              <img style="width: 20px;" :src="eyeopen" @click="toggleEye($event)" alt />
            </span>
          </template>
        </JhakaasInput>
        <div v-if="appConfig.featureEnabled.isCaptchaEnabled">
          <button @click="getAnotherCaptcha" class="button-link color-primary-hover" type="button">
            {{ $t("Click to refresh if you can not read") }}
          </button>
        </div>
      </div>
      <div class="signup-button">
        <button class="button-primary signup" type="submit">
          {{ $t("signup") }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import Utility from "@/mixins/Utility";
import googleAnalytics from "@/mixins/googleAnalytics.js";
import facebookPixelAnalytics from "@/mixins/facebookPixelAnalytics.js";
import cleverTap from "@/mixins/clevertap.js";
import branchIO from "@/mixins/branchEvents.js";
import { mapGetters, mapActions } from "vuex";
import { eventBus } from "@/eventBus";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { _providerId } from "@/provider-config.js";

export default {
  props: ["input", "inputtype"],
  data() {
    return {
      userName: null,
      userEmail: null,
      userMobileno: null,
      password: null,
      dob: null,
      gender: null,
      formError: null,
      captchaError: "",
      passwordError: "",
      showDateFilter: false,
      eyeopen: require("@/assets/icons/eye.svg"),
      eyeClose: require("@/assets/icons/eye-close.svg"),
      toast: null,
      ageRange: ["13-17", "18-30", "31-45", "45+"],
      providerUniqueId: _providerId,
      localDisplayLang: null,
      passwordPlaceholder: "",
      captchaPlaceholder: "",
      namePlaceholder: "",
      captcha: "",
      isActiveTerms: false,
      isActivePrivacy: false,
      captchaImage: null,
    };
  },
  computed: {
    ...mapGetters(["appConfig", "gettoken", "appConfig", "getRtl", "country"]),
  },
  watch: {
    formError() {
      setTimeout(() => {

        this.formError = null;
        this.captchaError = "";
        this.passwordError = "";

      }, 5000);
    },
  },
  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    //checking for change of language in local storage value
    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
    });
    //adding age ranges to the array.
    if (this.appConfig.featureEnabled.kidsMode) {
      this.ageRange.unshift("<13");
    }
  },
  mounted() {
    this.isActivePrivacy = window.PROVIDER_SETUP_CONFIG.isPrivacyEnbaled;
    this.isActiveTerms = window.PROVIDER_SETUP_CONFIG.isTermsEnabled;
    console.log("inputtype", this.inputtype);

    if (this.appConfig.featureEnabled.isCaptchaEnabled) {
      // Get Captcha Image for display
      this.getCaptchaImage();
    }

    this.setupForm();
    this.passwordPlaceholder = this.$t("password");
    this.captchaPlaceholder = this.$t("Enter captcha");
    this.namePlaceholder = this.$t("name");
    eventBus.$on("auth-close", () => {
      this.$emit("closePopup");
    });

    eventBus.$on("social-captch-event", (data) => {
      console.log("THIS IS THE SOCIAL -- EVENT -- ", data);

      let info = {
        data,
      };
      info.formType = "socialCaptcha";
      // this.formInfo = info;
      this.$emit("change", info);
    });

    //social signup.
    eventBus.$on("social-signup-response", (payload) => {
      console.log("THE SIGNUP/SOCIAL - PAYLOAD", payload);

      let signup_type = payload.data.app;

      const browser = this.getPlatformType();

      let data = {
        sign_up_mode: signup_type === "FB" ? "Facebook" : signup_type === "Google" ? "Google" : "",
        status: "Success",
        email: "",
        mobile: "",
        platform: this.getPlatform(),
        platform_type: browser.browser,
      };

      // Firebase Analytics Events.
      this.SignupEvent(data);

      // Facebook Pixel Events
      this.faceBookCompleteRegistrationEvent(data);

      //clevertap signup event.
      this.signUpCleverEvent(data);
    });

    //Normal email & password signup.
    eventBus.$on("signup-response", (payload) => {
      let data = {};
      console.log("payload ------ sign up", payload);
      if (payload && payload.response && payload.response.reason) {
        console.log('siign up captcha response=========', payload.response.reason)
        this.formError = this.$t(payload.response.reason);

        //analytics event.
        data.sign_up_mode = this.inputtype === "Mobile" ? "Mobile" : "Email";
        data.status = "Failed";
        data.error_reason = payload.response.reason;
        // data.Subscriber_ID = payload.response.success;
        data.email = this.userEmail;
        data.mobile = this.userMobileno;
      }
      else {
        let template = {};
        // data.Subscriber_ID = payload.response.success;
        if (this.inputtype === "Mobile") {
          template.formType = "mobile";
          template.inputtype = "Mobile";
          this.triggerSnackbarEvent();
          template.input = this.userMobileno;
          this.$emit("change", template);

          //analytics signup mobile event.
          data.sign_up_mode = "Mobile";
          data.status = "Success";
          data.mobile = this.userMobileno;
        } else if (this.inputtype === "Email") {
          template.formType = "email";
          template.inputtype = "Email";
          template.input = this.userEmail;
          this.triggerSnackbarEvent();
          this.$emit("change", template);

          //analytics signup email event.
          data.sign_up_mode = "Email";
          data.status = "Success";
          data.email = this.userEmail;
        }

        const browser = this.getPlatformType();

        data.platform = this.getPlatform();

        data.platform_type = browser.browser;

        // Firebase Analytics Events.
        this.SignupEvent(data);

        // Facebook Pixel Events
        this.faceBookCompleteRegistrationEvent(data);

        let login_data = {
          Name: payload.data.subscribername ? payload.data.subscribername : "",
          Identity: payload.response.success ? payload.response.success : "",
          Email: payload.data.email ? payload.data.email : "",
          Phone: payload.data.mobileno ? payload.data.mobileno : "",
          Gender: payload.data.gender ? (payload.data.gender === "MALE" ? "M" : "F") : "",
          DOB: payload.data.dob ? moment(payload.data.dob).format("DD-MM-YYYY") : "",
          Photo: payload.data.picture ? payload.data.picture : "",
          "MSG-email": true,
          "MSG-push": true,
          "MSG-sms": true,
        };

        //clevertap user login sdk event.
        this.loginCleverEvent(login_data);

        //clevertap push user profile.
        // clevertap.profile.push({
        //   Site: {
        //     RegistrationStartDate: this.displayDateFormat()
        //   }
        // });

        //clevertap signup event.
        this.signUpCleverEvent(data);

        //branch signup event
        this.signupBranchEvent(data);
      }
    });
  },
  methods: {
    ...mapActions(["getCaptcha"]),
    mobileVerify() {
      if (!this.verifyOtp(this.profilePin)) return;
      let payload = {
        email: this.userEmail,
      };

      if (this.profilePin) {
        payload.otp = this.profilePin;
      }

      this.mobileVerifyNew(payload)

    },
    getAnotherCaptcha() {
      this.getCaptchaImage();
    },
    showTermsOfUse() {
      let currentLanguage = this.getCurrentLanguageUrlBase();
      if (!window.PROVIDER_SETUP_CONFIG.isTermsEnabled) {
        return;
      }

      this.$router.push({ name: 'termsofuse', params: { lang: currentLanguage } });
    },
    showPrivacyPolicy() {
      let currentLanguage = this.getCurrentLanguageUrlBase();
      if (!window.PROVIDER_SETUP_CONFIG.isPrivacyEnbaled) {
        return;
      }

      this.$router.push({ name: 'privacypolicy', params: { lang: currentLanguage } });

    },

    validatePassword(password) {
      this.passwordError = "";
      if (!password) {
        this.passwordError = this.$t("Password Required!");
        return false;
      } else if (password.length < 6) {
        this.passwordError = this.$t("Password should be minimum 6 characters");
        return false;
      } else if (password.length > 16) {
        this.passwordError = this.$t("Password should be maximum 16 characters");
        return false;
      }

      return true;
    },

    getCaptchaImage() {
      this.getCaptcha()
        .then((data) => {
          if (data.reason) {
            this.captchaError = this.$t(data.reason);
            return;
          }

          console.log("CAPCTHA PAYLOAD ---- ", data);

          this.captchaImage = data.payload;
          this.captchaReferenceId = data.referenceid;
        })
        .catch((error) => {
          console.log("Captcha error -> ", error);
        });
    },

    setupForm() {
      if (this.input) {
        if (this.inputtype === "Mobile") {
          this.userMobileno = this.input;
          // this.$refs.mobileno.disabled = true;
          let input = document.getElementById("userMobileno");
          if (input === null || input === undefined) return;
          input.style.backgroundColor = "#5C5C5D";
          input.style.opacity = "0.3";
        } else {
          this.userEmail = this.input;
          //this.$refs.email.disabled = true;
          let input = document.getElementById("userEmail");
          if (input === null || input === undefined) return;

          input.style.backgroundColor = "#5C5C5D";
          input.style.opacity = "0.3";
        }
      }
    },
    changeFrom(data) {
      let info = {
        formType: "lookup",
        inputtype: data
        // inputField: "Email"
      };
      this.$emit("change", info);
    },

    signupUser() {
      // if (!this.validateName(this.userName) || !this.validateCountryCode()) return;

      if (this.inputtype === "Email") {
        if (
          // !this.validateEmail(this.userEmail) ||
          !this.validatePassword(this.password)
          // || !this.validateDob(this.dob)
        )
          return;
      } else {
        if (
          !this.validateMobileno(this.userMobileno) ||
          !this.validatePassword(this.password)
          //|| !this.validateDob(this.dob)
        )
          return;
      }

      let payload = {
        devicetype: "PC",
        // subscribername: this.userName,
        country: this.country.CountryCode,
      };

      if (this.captcha && this.appConfig.featureEnabled.isCaptchaEnabled) {
        payload.captcha = JSON.stringify({
          text: this.captcha,
          referenceid: this.captchaReferenceId,
        });
      } else if (!this.captcha && this.appConfig.featureEnabled.isCaptchaEnabled) {
        this.captchaError = this.$t("Please enter Captcha");
        return;
      }

      if (this.inputtype === "Mobile") {
        payload.mobileno = this.userMobileno;
      } else {
        payload.email = this.userEmail;
      }
      if (this.password) {
        payload.password = this.password;
        localStorage.setItem("usersignuppassword", this.password);
      }

      console.log("signup payload", payload);

      eventBus.$emit("subscriberSignup", payload);
    },

    redirectLookup() {
      let template = {};
      template.formType = "lookup";
      template.input = this.userEmail;
      console.log("template", template);
      this.$emit("change", template);
    },

    triggerSnackbarEvent() {
      let payload = {
        state: true,
        message:
          this.inputtype === "Email"
            ? "OTP has been sent to your mail address"
            : "OTP has been sent to your mobile number",
        color: "green",
      };
      eventBus.$emit("snackBarMessage", payload);
    },

    validateDob(dob) {
      this.formError = null;
      if (!dob) {
        this.formError = $t("Please select age.");
        return false;
      } else if (dob) {
        let d = new Date();
        let currentYear = d.getFullYear();
        let userYear = moment(dob)
          .format("YYYY-MM-DD")
          .split("-")[0];
        if (currentYear - userYear >= 0) {
          if (currentYear - userYear < 13) {
            this.formError = "Age should be greater than 13";
            return false;
          }
        }
      }
      return true;
    },

    closeSignup() {
      this.$emit("closePopup");
    },
  },
  components: {
    DatePicker,
    JhakaasInput: () => import("../../../utils/JhakaasInput.vue"),
  },
  mixins: [Utility, googleAnalytics, cleverTap, branchIO, facebookPixelAnalytics],
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
@import "./signup.scss"
</style>
